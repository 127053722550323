/* src/app/shared/components/loader/loader.component.scss */
.fullscreen-loader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10002;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.block-line-loader.overlay {
  -webkit-backdrop-filter: none;
  backdrop-filter: none;
}
.block-line-loader.overlay-black {
  background-color: inherit;
}
.block-line-loader.overlay-white {
  background-color: inherit;
}
.block-line-loader.inline-loader {
  position: relative;
  display: block;
  width: unset;
  height: unset;
  z-index: unset;
}
.loader-container {
  max-width: 7rem;
  height: 6rem;
  display: flex;
  gap: 5px;
  flex-direction: row;
  overflow: hidden;
}
.loader-container__ball {
  width: 1rem;
  height: 1rem;
  background-color: #3a7bbb;
  border-radius: 50%;
  transition: all 0.5s ease-in-out;
  animation: loader 1.5s ease-in-out infinite;
}
.loader-container .ball-1 {
  animation-delay: 0s;
}
.loader-container .ball-2 {
  animation-delay: 0.1s;
}
.loader-container .ball-3 {
  animation-delay: 0.2s;
}
.loader-container .ball-4 {
  animation-delay: 0.3s;
}
.loader-container .loading-text {
  color: #5b5756;
  font-weight: bold;
}
.dot {
  margin-left: 2px;
  font-size: 3rem;
  color: #5b5756;
  animation: blink 1.5s infinite;
}
.dot:nth-child(2) {
  animation-delay: 0.3s;
}
.dot:nth-child(3) {
  animation-delay: 0.6s;
}
@keyframes loader {
  0%, 10% {
    transform: translateY(0);
    height: 1rem;
    border-radius: 50%;
    background-color: #37a1e8;
  }
  50%, 60% {
    transform: translateY(4rem);
    height: 1rem;
    border-radius: 50%;
  }
  25%, 75% {
    height: 2rem;
    border-radius: 5px;
  }
  100% {
    transform: translateY(0);
    height: 1rem;
    border-radius: 50%;
  }
}
@keyframes blink {
  0%, 100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}
.inline-loader {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  z-index: 1001;
}
.overlay {
  -webkit-backdrop-filter: blur(3px);
  backdrop-filter: blur(3px);
}
.overlay-black {
  background-color: rgba(0, 0, 0, 0.4);
}
.overlay-white {
  background-color: rgba(255, 255, 255, 0.7);
}
/*# sourceMappingURL=loader.component.css.map */
