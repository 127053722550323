export enum EnvironmentsEnum {
  Environment = 'environment',
  Development = 'development',
  Testing = 'testing',
  Staging = 'staging',
  Production = 'production'
}

export interface IAuthorizationBearerParams {
  grant_type: string;
  client_id: string;
  client_secret: string;
}

export interface IProductApiConfig {
  account_id: number;
  auth_key: string;
  domain_key: string;
  request_id: number;
  br_uid_2: string;
}

interface IEnvironment {
  liveBaseUrl: string;
  version: string;
  environment: EnvironmentsEnum;
  serverStartTime: number;
  production: boolean;
  port: number;
  apiUrl: string;
  brxmBaseUrl: string;
  cmsBaseUrl: string;
  aladdinOnDemandUrl: string;
  authorizationBearerParams: IAuthorizationBearerParams;
  plpApiBaseUrl: string;
  plpApiConfig: IProductApiConfig;
  pdpApiBaseUrl: string;
  pdpAvailabilityModalUrl: string;
}

export default IEnvironment;
