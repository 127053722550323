@if (isLoading) {
  <div
    class="overlay"
    [ngClass]="{
      'fullscreen-loader': fullScreen,
      'inline-loader': !fullScreen,
      'block-line-loader': !fullScreen && isBlockLineLoader
    }"
    [class]="overlay"
  >
    <div class="loader-container">
      @for (ball of balls; track ball) {
        <div class="loader-container__ball" [ngClass]="'ball-' + ball"></div>
      }
    </div>
    @if (title) {
      <div class="loading-text">
        {{ title }}
        @for (dot of dots; track dot) {
          <span class="dot">.</span>
        }
      </div>
    }
  </div>
}
