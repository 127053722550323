import IEnvironment, { EnvironmentsEnum } from '@environments/environment.interface';

const baseCmsUrl = 'https://dev-cmsapi.mohawkind.com/site/ac';

export const environment: IEnvironment = {
  liveBaseUrl: 'https://dev-alpha.aladdincommercial.com',
  version: '0.0.0',
  environment: EnvironmentsEnum.Development,
  serverStartTime: new Date().getTime(),
  production: false,
  port: 0,
  apiUrl: '',
  brxmBaseUrl: baseCmsUrl,
  cmsBaseUrl: `${baseCmsUrl}/resourceapi`,
  aladdinOnDemandUrl: 'https://api.cjjsup9vs1-mohawkcar1-s1-public.model-t.cc.commerce.ondemand.com',
  authorizationBearerParams: {
    grant_type: 'client_credentials',
    client_id: 'aladdin',
    client_secret: 'Mohawk@123'
  },
  plpApiBaseUrl: 'https://staging-core.dxpapi.com/api/v1/core',
  plpApiConfig: {
    account_id: 7748,
    auth_key: 'ucw5lxpy0ugcqvfi',
    domain_key: 'aladdincommercial',
    request_id: 7637386574986,
    br_uid_2: 'uid%3D8500317268860%3Av%3D15.0%3Ats%3D1727725357937%3Ahc%3D70'
  },
  pdpApiBaseUrl: 'https://pimtoprodresidentialapi.azurewebsites.net/api/GetAladdinProductsBystylenumber',
  pdpAvailabilityModalUrl: 'https://pimtoprodresidentialapi.azurewebsites.net/api/GetAladdinInventoryStatusdetails'
};
